/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../gateway/header"
import Footer from "../shared/footer"
import { MuiThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "../../theme";
import GatewayIsiContainer from "../gateway/gateway-isi-container";

import sal from "sal.js";
import ScreenshotMode from "../../utilities/screenshot-mode";

import "../../global.scss";
import NoRepModal, { getNoVacancyParamValue } from "../shared/no-rep-modal";
const Layout = ({ children, location, references, jobCode, jobCodeDate, locationHostName }) => {
    const [entryModalShown, showEntryModal] = useState(false);
    const [showIsiTray, setShowIsiTray] = useState(true);
    const [noRep, setNoRep] = useState(false);
    let data_domain_script;

    React.useEffect(
        () => {
            setNoRep(getNoVacancyParamValue(location));
            if (noRep) {
                showEntryModal(false);
                sessionStorage.setItem('showEntryModal', false)
            }
        }
        , [location, noRep]
    )

    React.useEffect(
        () => {
            // open ISI tray with this hash
            if (window.location.hash === '#isi') {
                setTimeout(function () {
                    var psp = document.querySelector('#psp-panel');
                    psp.setAttribute('data-state', 'tallbutcollapse');
                    document.body.classList.add('no-overflow');
                }, 300);
            }

            // Check if animations have been disabled for screenshot mode
            if (ScreenshotMode.disableAnimation) {
                // Animations have been disabled - add a class to our <body>
                // element to undo the CSS definitions put in place by the
                // various "data-sal" HTML attributes
                document.body.classList.add("disable-sal");
            } else {
                // Animations haven't been disabled - start scroll animations
                // normally
                sal({
                    threshold: 0.2
                });
            }


            sessionStorage.setItem('hostName', location.hostname);


            // Check if the "showEntryModal" session storage item has been
            // defined
            if (sessionStorage.getItem('showEntryModal') === null) {
                // The "showEntryModal" session storage item doesn't yet exist -
                // Check if the modal has been disabled
                if (!ScreenshotMode.disableEntryModal) {
                    // The modal hasn't been disabled - set the "entryModalShown"
                    // state value, which if set to true will trigger the modal
                    // component being included in the header component
                    showEntryModal(true);
                }
            }

            // Check if the ISI tray has been disabled for screenshot mode
            if (ScreenshotMode.disableIsiTray) {
                if (showIsiTray)
                    setShowIsiTray(false);
            } else {
                if (!showIsiTray)
                    setShowIsiTray(true);
            }

            // Check if the cookie consent banner has been disabled for screenshot mode
            if (ScreenshotMode.disableCookieBanner)
                document.body.classList.add("disable-cookie-banner");
        },
        [
            entryModalShown,
            showIsiTray
        ]
    );

    const data = useStaticQuery(graphql`
    query LandingTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

    if (locationHostName === "auryxiahcp-staging.azurewebsites.net") {
        data_domain_script = "58455c1a-7215-4353-8182-17bb00627ea9-test";
    } else {
        data_domain_script = "58455c1a-7215-4353-8182-17bb00627ea9";
    }

    return (
        <MuiThemeProvider theme={globalTheme}>
            <a href="#main-content" id="skip-link">Skip to content</a>
            <div id="header-backdrop"></div>
            {noRep && <NoRepModal externalLink="https://www.auryxia.com/" />}
            <Header siteTitle={data.site.siteMetadata ?.title || `Title`}
                entryModalShown={entryModalShown}
                onEntryModalDismissed={() => {
                    sessionStorage.setItem('showEntryModal', false)
                    showEntryModal(false)
                }}
                siteAudience='gateway' />

            <main id="main-content" aria-label={data.site.siteMetadata ?.title + " content"}>{children}</main>

            <GatewayIsiContainer
                showIsiTray={showIsiTray}
                references={references} />

            <Footer siteTitle={data.site.siteMetadata ?.title || `Title`}
                siteAudience='gateway'
                jobCode={jobCode}
                jobCodeDate={jobCodeDate}
                url_hostname={data_domain_script} />

        </MuiThemeProvider>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    references: PropTypes.array,
    jobCode: PropTypes.string,
    jobCodeDate: PropTypes.string,
    locationHostName: PropTypes.string
}

export default Layout

import * as React from "react"

export default function GatewayIsi({ observerFlag, references }) {

	return (
		<section aria-label="Important Safety Information" className="isi" ref={observerFlag}>
			<div>
				<div className="isi-bar">
					<div>
						<h2>IMPORTANT SAFETY INFORMATION AND INDICATION</h2>
					</div>
				</div>
				<div className="flex-row">
					<div>
						<p><strong>CONTRAINDICATION</strong></p>
						<p>AURYXIA<sup>&#x00ae;</sup> (ferric citrate) is contraindicated in patients with iron overload syndromes, e.g., hemochromatosis</p>

						<p><strong>WARNINGS AND PRECAUTIONS</strong></p>
						<ul>
							<li><strong>Iron Overload:</strong> Increases in serum ferritin and transferrin saturation (TSAT) were observed in clinical trials with AURYXIA in patients with chronic kidney disease (CKD) on dialysis treated for hyperphosphatemia, which may lead to excessive elevations in iron stores. Assess iron parameters prior to initiating AURYXIA and monitor while on therapy. Patients receiving concomitant intravenous (IV) iron may require a reduction in dose or discontinuation of IV iron therapy</li>
							<li><strong>Risk of Overdosage in Children Due to Accidental Ingestion:</strong> Accidental ingestion and resulting overdose of iron-containing products is a leading cause of fatal poisoning in children under 6 years of age. Advise patients of the risks to children and to keep AURYXIA out of the reach of children</li>
						</ul>

						<p><strong>ADVERSE REACTIONS</strong></p>
						<p>The most common adverse reactions reported with AURYXIA in clinical trials were:</p>
						<ul>
							<li><u>Hyperphosphatemia in CKD on Dialysis:</u> Diarrhea (21%), discolored feces (19%), nausea (11%), constipation (8%), vomiting (7%) and cough (6%)</li>
							<li><u>Iron Deficiency Anemia in CKD Not on Dialysis</u>: Discolored feces (22%), diarrhea (21%), constipation (18%), nausea (10%), abdominal pain (5%) and hyperkalemia (5%)</li>
						</ul>

						<p><strong>SPECIFIC POPULATIONS</strong></p>
						<ul>
							<li><strong>Pregnancy and Lactation:</strong> There are no available data on AURYXIA use in pregnant women to inform a drug-associated risk of major birth defects and miscarriage. However, an overdose of iron in pregnant women may carry a risk for spontaneous abortion, gestational diabetes and fetal malformation. Data from rat studies have shown the transfer of iron into milk, hence, there is a possibility of infant exposure when AURYXIA is administered to a nursing woman</li>
						</ul>

						<p className="hpp"><strong>INDICATION</strong></p>
						<p className="nopad">AURYXIA<sup>&#x00ae;</sup> (ferric citrate) is indicated for:</p>
						<ul>
							<li>The control of serum phosphorus levels in adult patients with chronic kidney disease on dialysis</li>
							<li>The treatment of iron deficiency anemia in adult patients with chronic kidney disease not on dialysis</li>
						</ul>

						<p>To report suspected adverse reactions, contact Akebia Therapeutics, Inc. at <a href="tel:18444453799">1-844-445-3799</a></p>
						<p>Please see full <strong>
							<a href="https://www.auryxia.com/wp-content/uploads/Auryxia_PI.pdf" target="_blank" rel="noreferrer">Prescribing Information</a>
						</strong></p>

						{references && (
							<div className="references">
								<h3>REFERENCE{references.length > 1 ? 'S' : null}</h3>
								<ol>
									{references.map((ref, i) => (
										<li key={i}>{ref}</li>
									))}
								</ol>
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	)

}
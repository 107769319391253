import React from "react";
import clsx from "clsx";
import "../../global.scss"
import { makeStyles } from "@material-ui/core/styles";

import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import GatewayIsi from "./isi-gateway";

const drawerHeight = "calc(100% - 5.25rem)";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex"
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		position: "fixed",
		top: "unset",
		bottom: "42rem",
		height: "5.25rem",
		minHeight: "5.25rem",
		textTransform: "uppercase",
		fontSize: "2rem",
		justifyContent: "space-between",
		backgroundColor: "#ffa300",
		filter: "drop-shadow(0px -8px 6px rgba(0,0,0,0.15))",
		transition: theme.transitions.create(["bottom"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		[theme.breakpoints.up("sm")]: {
			bottom: "21.7rem"
		}
	},
	appBarShift: {
		width: "100%",
		bottom: "calc(100% - 5.25rem)",
		transition: theme.transitions.create(["bottom"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	appBarBar: {
		display: "flex",
		flexFlow: "row wrap",
		justifyContent: "space-between",
		alignItems: "center",
		margin: "0 auto",
		width: "100%",
		maxWidth: "136rem",
		padding: "1rem 0",
		[theme.breakpoints.up("lg")]: {
			padding: "0 2rem",
		}
	},
	appBarTitle: {
		flexGrow: 1,
		width: "50%",
		padding: 0,
		margin: 0,
		[theme.breakpoints.up("md")]: {
			width: "80%"
		}
	},
	appBarHeader: {
		color: "#2a2a32",
		fontFamily: "'Avenir LT W01_85 Heavy1475544', sans-serif",
		fontSize: "1.5rem",
		lineHeight: "2rem",
		margin: 0,
		[theme.breakpoints.up("sm")]: {
			fontSize: "2.25rem",
		}
	},
	appButtonText: {
		color: "#2a2a32",
		fontFamily: "'Avenir LT W01_85 Heavy1475544', sans-serif",
		fontSize: "1.5rem",
		[theme.breakpoints.up("sm")]: {
			fontSize: "2.25rem",
		}
	},
	appBarBttnWrap: {
		padding: 0
	},
	appIconButton: {
		borderRadius: 0,
		'&:hover': {
			backgroundColor: "transparent",
		}
	},
	appButtonCollapse: {
		opacity: 0.7,
	},
	hide: {
		display: "none"
	},
	drawer: {
		height: drawerHeight,
		flexShrink: 0
	},
	drawerOpen: {
		height: drawerHeight,
		transition: theme.transitions.create("height", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		width: "auto",
		overflowX: "hidden",
		overflowY: "scroll",
		display: "block",
		border: "none",
	},
	drawerClose: {
		transition: theme.transitions.create("height", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		display: "block",
		overflow: "hidden",
		width: "auto",
		height: "42rem",
		[theme.breakpoints.up("sm")]: {
			height: "21.7rem"
		},
		border: "none",
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: theme.spacing(0, 0),
		fontSize: "16px",
		height: "5.25rem",
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		minHeight: "5.25rem",
	}
}));


export default function MiniDrawer({ stickyFlag, siteAudience, references }) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	if (typeof window !== 'undefined') {
		if (sessionStorage.getItem('collapsePSP')) {
			setTimeout(function () {
				var psp = document.querySelector('#psp-panel');

                // Pages initialize at least twice and this can cause an error
                // if the PSP is disabled after the initial component mounted -
                // adding another check here in case "#psp-panel" no longer exists
                // on the DOM
                if (psp)
    				psp.setAttribute('data-state', 'collapse');
			}, 200)
		}
	}

	const toggleDrawer = () => {
		open ? setOpen(false) : setOpen(true);
		var psp = document.querySelector('#psp-panel');
		open ? psp.setAttribute('data-state', 'default') : psp.setAttribute('data-state', 'tall');
		open ? document.body.classList.remove('no-overflow') : document.body.classList.add('no-overflow');

		//console.log('toggleDrawer ');
	};

	const openButCollapseDrawer = () => {
		setOpen(true);
		var psp = document.querySelector('#psp-panel');
		psp.setAttribute('data-state', 'tallbutcollapse');
		document.body.classList.add('no-overflow');

		sessionStorage.removeItem('collapsePSP');

		// console.log('open but collapse ');
	};

	const collapseDrawer = () => {
		var psp = document.querySelector('#psp-panel');
		psp.setAttribute('data-state', 'collapse');
		document.body.classList.remove('no-overflow');

		sessionStorage.setItem('collapsePSP', true);

		//console.log('collapseDrawer');
	}

	return (
		<section id="psp-panel" data-state="default" className={[stickyFlag, siteAudience].join(' ')}>
			<AppBar position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open
				})}
			>
				<Toolbar variant="dense" disableGutters={false} >
					<section className={classes.appBarBar}>
						<div className={classes.appBarTitle}>
							<h2 className={classes.appBarHeader} variant="h4" wrap="true">IMPORTANT SAFETY INFORMATION<br/> AND INDICATION</h2>
						</div>
						<div className={classes.appBarBttnWrap}>
							{/* open & half close */}
							<span className="defaultbuttons">
								<IconButton
									color="inherit"
									aria-label="Open"
									onClick={toggleDrawer}
									className={classes.appIconButton}
								>
									<span className={classes.appButtonText}>
										<span>{open ? "Show less " : "Show more "}</span>
										{open ?
											<KeyboardArrowDown viewBox="6 6 12 12" /> :
											<KeyboardArrowUp viewBox="6 6 12 12" />}
									</span>
								</IconButton>
                                
								{open ? null :
									<IconButton
										color="inherit"
										aria-label="Close"
										onClick={collapseDrawer}
										className={classes.appButtonCollapse}>
										<span>
											<KeyboardArrowDown viewBox="6 6 12 12" />
										</span>
									</IconButton>
								}
							</span>
							{/* open & fully close */}
							<span className="collapsebuttons">
								<IconButton
									color="inherit"
									aria-label="Open"
									onClick={openButCollapseDrawer}
									className={[classes.appIconButton, 'grow'].join(' ')}
								>
									<span className={classes.appButtonText}>
										<span>Show more</span> <KeyboardArrowUp viewBox="6 6 12 12" />
									</span>
								</IconButton>
								<IconButton
									color="inherit"
									aria-label="Open"
									onClick={collapseDrawer}
									className={[classes.appIconButton, 'shrink'].join(' ')}
								>
									<span className={classes.appButtonText}>
										<span>Show less</span> <KeyboardArrowDown viewBox="6 6 12 12" />
									</span>
								</IconButton>
							</span>
						</div>
					</section>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				anchor="bottom"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open
					})
				}}
			//onClose={console.log('close')}
			>
				<div>
					<GatewayIsi references={references} />
				</div>
			</Drawer>
		</section>
	);
}

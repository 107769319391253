import * as React from "react"
import PropTypes from "prop-types"
import { useMediaQuery } from '../../hook/mediaQueryHooks'
import { Link } from "gatsby"
import logo from "../../images/Auryxia-logo.svg"

import EntryModal from "../shared/entry-modal"

const openIsiTray = event => {
    event.preventDefault();
    setTimeout(function () {
        var psp = document.querySelector('#psp-panel');
        psp.setAttribute('data-state', 'tallbutcollapse');
        document.body.classList.add('no-overflow');
    }, 300)
};

const toggleModes = {
    auto: 0,
    forceOpen: 1,
    forceClose: 2
};



const toggleBurger = (event, toggleMode = toggleModes.auto) => {
    var header = document.querySelector('#global-navigation'),
        newMenuOpenState = null;

    switch (toggleMode) {
        case toggleModes.auto:
            newMenuOpenState = !header.classList.contains('open');
            break;

        case toggleModes.forceOpen:
            if (!header.classList.contains('open'))
                newMenuOpenState = true;
            break;

        case toggleModes.forceClose:
            if (header.classList.contains('open'))
                newMenuOpenState = false;
            break;

        default:
            break;
    }

    // If newMenuOpenState is null, don't make any changes to the menu's open state.
    // otherwise, we're opening or closing the menu based on whether it has been set
    // to true or false
    if (newMenuOpenState !== null) {
        var backdrop = document.querySelector('#header-backdrop'),
            pgheader = document.querySelector('header'),
            body = document.querySelector('body');

        if (newMenuOpenState) {
            // Open the menu
            body.classList.add('no-select');
            backdrop.style.display = 'block';
            header.classList.add('open');
            pgheader.classList.add('open');
        } else {
            // Close the menu
            body.classList.remove('no-select');
            backdrop.style.display = 'none';
            header.classList.remove('open');
            pgheader.classList.remove('open');
        }
    }
};

const CloseOpenMenu = () => {
    const mobile = useMediaQuery('(min-width: 767px)')

    if (mobile === true) {
        var myBackdrop = document.querySelector('#header-backdrop'),
            myHeader = document.querySelector('header#landing'),
            header = document.querySelector('#global-navigation'),
            pageHeader = document.querySelector('header'),
            myBody = document.querySelector('body');

        if (myHeader !== null && myHeader.classList.contains('open') === true) {
            myBody.classList.remove('no-select');
            myBackdrop.style.display = 'none';
            myHeader.classList.remove('open');
            pageHeader.classList.remove('open');
            header.classList.remove('open');
        }
        return (
            <span className="hidden">desktop</span>
        )
    }
    else {
        return (
            <span className="hidden">mobile</span>
        )
    }
}

const Header = ({ siteTitle, entryModalShown, siteAudience, externalLink }) => (

    <header aria-label={siteTitle + " header"} id="landing">
        {entryModalShown &&
            <EntryModal siteAudience={siteAudience} externalLink="https://www.auryxia.com/" />
        }
        <div id="global-navigation">
            <div id="gateway-navigation" className="flex-row">
                <div id="auryxia-logo" aria-label="AURYXIA® logo">
                    <Link to="/" title="Home">
                        <img src={logo} alt="AURYXIA® (ferric citrate) tablets" />
                    </Link>
                </div>
                <div id="header-navigation" className="header-navigation" aria-label="Auryxia Physician Navigation Menu">
                    <div className="navigation secondary">
                        <ul>
                            <li><a href="#isi" className="anchor-link" onClick={openIsiTray}>Important Safety Information</a></li>
                            <li><a href="https://www.auryxia.com/wp-content/uploads/Auryxia_PI.pdf" target="_blank" rel="noreferrer">Full Prescribing Information</a></li>
                            <li className="large-only"><a href="https://www.auryxia.com/">For Patients</a></li>
                            <li className="large-only"><a href="http://akebiacareshcp.com/">AkebiaCares</a></li>
                        </ul>
                        <p>FOR US HEALTHCARE PROFESSIONALS</p>
                    </div>
                    <div id="hamburger" onClick={toggleBurger} onKeyDown={toggleBurger} role="button" tabIndex={0}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>

                    <div id="header-links" className="navigation primary">
                        <nav>
                            <ul>
                                <li><a href="https://www.auryxia.com/">For Patients</a></li>
                                <li><a href="http://akebiacareshcp.com/">AkebiaCares</a></li>
                            </ul>
                        </nav>
                    </div>

                </div>
            </div>
        </div>
        <CloseOpenMenu />
    </header>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: `AURYXIA® (ferric citrate) tablets`,
}

export default Header

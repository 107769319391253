import React, {Fragment} from "react";
import GatewayIsi from "./isi-gateway";
import MiniDrawer from "./psp-gateway";
import {useInView} from "react-intersection-observer";


export default function GatewayIsiContainer({showIsiTray, references}) {
    let myThreshold = 0.24;
    if (typeof window !== 'undefined') {
        if (sessionStorage.getItem('collapsePSP')) {
            myThreshold = 0.05;
        }
    }

    const {ref, inView} = useInView({
        threshold: myThreshold,
        initialInView: true
    });

    let isiTray = null;
    if (showIsiTray !== false) {
        isiTray = <MiniDrawer
            siteAudience='gateway'
            stickyFlag={inView ? 'hidden' : 'visible'}
            references={references} />
    }

    return (
        <Fragment>
            <GatewayIsi
                observerFlag={ref}
                references={references} />

            {isiTray}
        </Fragment>
    )
}
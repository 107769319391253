import { createTheme } from '@material-ui/core/styles';

export const globalTheme = createTheme({
    breakpoints: {
        // let's match our variables.scss file 
        values: {
            xs: 320,
            sm: 768,
            md: 960,
            lg: 1200,
            xl: 1344
        },
    },
    palette: {
        primary: {
            // main: '#00518A', //ida blue
            main: '#DA7232', //hp orange
        },
        secondary: {
            main: '#878787',
        },
    },
    typography: {
        button: {
            fontFamily: "'Avenir LT W01_95 Black1475556', 'sans-serif'",
            fontSize: '11px',
            lineHeight: '16px',
            letterSpacing: '.5px',
            borderRadius: '0',
        }
    }
});

export const hpTheme = createTheme({
    palette: {
        primary: {
            main: '#DA7232',
        },
        secondary: {
            main: '#878787',
        },
    },
});

export const idaTheme = createTheme({
    palette: {
        primary: {
            main: '#00518A',
        },
        secondary: {
            main: '#878787',
        },
    },
});